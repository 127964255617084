<template>
  <div>
    <common-header :title="newsDetails.title" :cb="callback" v-if="newsDetails.title"></common-header>
    <!-- 加载动画start -->
    <loading v-show="loading == true"></loading>
    <!-- 加载动画end -->
    <div class="details-box" v-if="newsDetails.content">
      <!-- 详情头部start -->
      <div class="details-content-top">
        <!-- 详情内容start -->
        <div class="content" v-html="newsDetails.content"></div>
        <!-- 详情内容end -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState  } from 'vuex';
import { requestImgUrl,} from "@/utils/common";
import { formatNum } from "@/utils/utils";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.formatNum = (v) => formatNum(v);
    return {
      newsDetails: {},
      loading: true,
      title: "",
    }
  },
  mounted() {
    this.getNewsDetails({
      id: this.$route.query.id
    }).then((res)=> {
      this.newsDetails = res.data;
      this.loading = false;
    })
  },
  computed: {
    ...mapState(["orgInfo"]),
  },
  methods: {
    callback() {
      
    },
    ...mapActions(['getNewsDetails']),
  },
}
</script>
<style lang='less' scoped>
.rq{
  color: #bfbfbf;
  font-size: .26rem;
}
.logo {
  display: inline-block;
  width: 0.5rem;
  margin-right: 0.3rem;
}
.details-content-top {
  padding: 0.2rem 0.4rem 0.4rem 0.4rem;
  background: #fff;
}
.title {
  font-size: 0.32rem;
  color: #282828;
  margin-bottom: 0.42rem;
}
.top-de {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.details-box {
  position: relative;
  z-index: 1;

  .details-data {
    display: flex;
    .ms {
      font-size: 0.24rem;
      color: #bfbfbf;
      margin-right: 0.3rem;
      display: flex;
      align-items: center;
      i {
        display: inline-block;
        background-size: 100%;
        margin-right: 0.1rem;
      }
      span {
        line-height: 1;
      }
      .eye {
        background-image: url("../../assets/images/img/liulan-icon.png");
        width: 0.28rem;
        height: 0.19rem;
      }
      // .fabulous {
      //   background-image: url("../../assets/images/icon/zan-icon.png");
      //   width: 0.23rem;
      //   height: 0.24rem;
      // }
      // .pl {
      //   background-image: url("../../assets/images/icon/pinglun-icon.png");
      //   width: 0.23rem;
      //   height: 0.21rem;
      // }
    }
  }
  .content{
    font-size: .28rem;
    color: #595959;
    line-height: 1.6;
    text-align: justify;
  }
}
</style>